import React from 'react';
import { connect } from 'react-redux';

import { ErrorMessage, FastField, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { initiateResetPassword } from '../../../modules/actions/user';
import { trackEvent } from '../../components/Tracking/Matomo';
import Button from '../../components/form-elements/Button';

const passwordResetSchema = Yup.object().shape({
    email: Yup.string().email('Bitte gültige E-Mail eingeben.').required('Pflichtfeld.'),
});

function PasswordResetInitialization({ initiateResetPassword }) {
    const handleResetPasswordSubmit = async ({ email }, actions) => {
        const { data: message } = await initiateResetPassword({ email });
        trackEvent({
            category: 'Account',
            action: 'Submit-Initiate-Passwort-Vergessen',
            label: 'initiate-passwordreset',
        });
        window.UIkit.notification(message, { status: 'success', pos: 'top-right' });
        actions.setSubmitting(false);
    };
    return (
        <div className="uk-container uk-container-xsmall">
            <Formik initialValues={{ email: '' }} validationSchema={passwordResetSchema} onSubmit={handleResetPasswordSubmit}>
                <Form>
                    <h3>Passwort vergessen?</h3>
                    <p>
                        Geben Sie hier Ihre E-Mail-Adresse ein, mit der Sie sich bei Autoteile Plus Service registriert haben. Wir schicken
                        Ihnen direkt eine E-Mail mit einem Link zu, über den Sie Ihr Passwort neu vergeben können.
                    </p>
                    <div className="uk-margin">
                        <FastField type="email" name="email" className="uk-input" placeholder="E-Mail Adresse eingeben" />
                        <ErrorMessage component="div" className="uk-text-danger" name="email" />
                    </div>
                    <div className="uk-margin">
                        <Button fullWidth type="submit">
                            Abschicken
                        </Button>
                    </div>
                </Form>
            </Formik>
        </div>
    );
}

const mapDispatchToProps = { initiateResetPassword };

export default connect(null, mapDispatchToProps)(PasswordResetInitialization);
